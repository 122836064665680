<template>

    <div class="contentWrapper">
      <div class="contentBox">
              <div class="timelineSwiper">
                  
                  <swiper  
                           @swiper="onSwiper"
                           navigation
                           ref="swiper"
                           :slides-per-view="2.5"
                           :style="{'--swiper-navigation-color': '#000'}"
                           @slideChange="onSlideChange"
                            >
                  <swiper-slide v-for="(timelineEntry,index) in timeline" :key="index" class="swiper-slide">    
                      <timeline-card
                          :timelineItem="timelineEntry"
                          v-on:openDetail="openDetail()"
                          >
                      </timeline-card> 
                  
                  </swiper-slide>
                          
                  </swiper>
                 
            </div>
        </div>
    </div>

</template>

<script>
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper-bundle.css'
import "swiper/components/navigation/navigation.scss";
SwiperCore.use([Navigation, Pagination, A11y]);

import TimelineCard from "@/components/TimelineCard.vue";
export default {
  data() {
    return {
      assetUrl: process.env.assetUrl,
      swiperOptions: {
        direction: "horizontal",
        mousewheel: false,
        slidesPerView: 'auto',
        spaceBetween: 0,     
        
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    TimelineCard,
    Swiper,
    SwiperSlide
  },
  props:{
      timelineIndex:Number,
      titel: String,
      untertitel: String
  },

  data(){
    return{ 
      swiperOptions: {
        direction: "horizontal",
        mousewheel: false,
        slidesPerView: 'auto',
        spaceBetween: 0,     
        on: {
          slideChange: () => {
            this.activeSlide = this.$refs.swiper.$swiper.activeIndex;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        activeIndex:0,
      },

    }
    
  }, 
  methods: {
    slideNext(){
      console.log(this.$refs.swiper)
      //this.$refs.swiper.nextSlide()
    },
    checklength(array) {
      let val = false;
      console.log(array);
      array.length > 4 ? (val = true) : (val = false);
      return val;
    },
    checkTimeline(array) {
      let val = false;
      console.log(array);
      array.length > 4 ? (val = true) : (val = false);
      return val;
    },
    openDetail(){
            this.$emit('openDetail')
            
    }
  },
  computed: {
    
    timeline() {
      return this.$store.getters.getTimeline;
    },
  },
  async  created() {
    await this.$store.dispatch("serverStart")
    await this.$store.dispatch("getTimeline");


  },
  setup() {

      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
      };
    },
};
</script>

<style lang="scss" scoped>
.contentWrapper{
 display: flex;
 justify-content: center;
 padding-bottom: 50px;
}

.extrapadding {
  margin-bottom: 200px;
}
.zeitstrahl {
  margin-top: 1vh;
  width: 90%;
  float:right;
  margin-right: 20px;
}

.KapitelHeadline {
  font-size: 50px;
  font-family: "Frutiger-Bold";
  text-decoration: underline;
  color: #000;
  margin-left: 15%;
}
.KapitelHeadlineH2type2 {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  margin-left: 15%;
}

.KapitelHeadlineH2 {
  font-size: 20px;
  margin-left: 15%;
}
.customContainer {
  background: white;
  width: 95%;
  left: 2.5%;
  height: 197%;
  margin-top: 3%;
}
.KapitelHeadline {
  font-size: 50px;
  font-family: "Frutiger-Bold";
  text-decoration: underline;
  color: #000;
}
.KapitelHeadlineH2type2 {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
}
.contentBox {
  margin-left: 0;
  margin-top: 7vh;
  width: 100%;
}
.contentBox > img {
  margin-left: -10%;
  margin-top: 20px;
}
.contentBox{
  max-width: 1300px;
}

.KapitelHeadlineH2 {
  font-size: 20px;
}
.contentParagraph {
  margin-top: 5vh;
}

.swiper-button-next{
  color: #000;
}

.swiper-button-prev{
  color: #000;
}

.swiper-slide{
  width: 38% !important;
 
  
  @media(max-width: 1200px) {
    width: 38% !important;
  }
  @media(max-width: 768px) {
    width: 45% !important;
  }
   @media(max-width: 500px) {
    width: 80% !important;
  }
}

.swiper-button-next,
.swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.5);
    right:10px;
    padding: 30px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
}

.timelineSwiper{
  position: relative;
}
</style>