import { createStore } from 'vuex'
import axios from 'axios'

const base_url=process.env.VUE_APP_API_URL
export default createStore({
  state: {
    token: null,
    refreshToken: null,
    navigation:null,
    videoCard:null,
    timeline:null,
    details:null,
    currentDetail:null,
    info:null
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setRefreshToken(state, token) {
      state.refreshToken = token
    },
    setVideoCards(state,videoCard){
      state.videoCard=videoCard
    },
    setTimeline(state,timeline){
      state.timeline=timeline
    },  
    setDetails(state,details){
      state.details=details
    },
    setCurrentDetail(state,id){
      let temp=state.details.filter(el=> el.id == id)
      state.currentDetail=temp[0]
    },
    setInfo(state,info){
      state.info=info
    }
  },
  actions: {
    async serverStart({ commit, dispatch }) {

      return new Promise((resolve, reject) => {
        axios.post(base_url+'/auth/login', {
          email: "schwarz@runze-casper.de",
          password: "admin123",
          mode: "cookie"
        }).then(resp => {
          const token = resp.data.data.access_token
          commit('setToken', resp.data.data.access_token)
          commit('setRefreshToken', resp.data.data.refresh_token)
          console.log("login",resp.data)
          resolve(resp)
        }).catch(err => {
        })
      })
    },
    async getVideos({commit, dispatch}){
        const res= await axios.get(base_url+'/items/video_card', {
          'headers': {
            'Authorization': 'Bearer ' + this.state.token,
          }
        })
        console.log("video",res)
        commit("setVideoCards",res.data.data)
    },
    async getTimeline({commit, dispatch}){
      const res= await axios.get(base_url+'/items/zeitreihe', {
        'headers': {
          'Authorization': 'Bearer ' + this.state.token,
        }
      })
      console.log(res)
      let timeline= res.data.data.sort(function compare( a, b ){
        if ( a.sort < b.sort ){
          return -1;
        }
        if ( a.sort > b.sort ){
          return 1;
        }
        return 0;
      })
      commit("setTimeline",res.data.data)
    },
    async getDetails({commit, dispatch}){
      const res= await axios.get(base_url+'/items/detail_view', {
        'headers': {
          'Authorization': 'Bearer ' + this.state.token,
        }
      })
      console.log("detail", res)
      commit("setDetails",res.data.data)
    },
    setCurrentDetail({commit, dispatch},id){
      console.log("setCurrentDetail",id)
      commit("setCurrentDetail",id)
    },
    async getInfo({commit, dispatch},page){
      const res= await axios.get(base_url+'/items/info?filter[seite][_eq]='+page, {
        'headers': {
          'Authorization': 'Bearer ' + this.state.token,
        },        
      })
      console.log(res)
      commit("setInfo",res.data.data)
    
    },
  },  
  getters: {
    getVideos: state => {
      return state.videoCard
    },
    getTimeline: state => {
      return state.timeline
    },
    getDetails: state => {
      return state.details
    },
    getCurrentDetail: state => {
      return state.currentDetail
    },
    getInfo: state => {
      return state.info
    },
  },
  modules: {
  }
})



