<template>
    <div class="videoBackground" ref="videoBackground">
        <div class="videoBackgroundInner">
            <div class="videoCard" v-if="details"  ref="videoCard">
                <div :key="preview" class="videoBoard" @mouseenter="showPlay()" @mouseleave="hidePlay()">
                    <video v-if="showPreview"  :src='"https://cms.zuhause-in-niedersachsen.de/assets/"+details.preview_video' loop autoplay ref="videoplayer" class="previewVideo" ></video>
                    <video v-if="!showPreview" :src='"https://cms.zuhause-in-niedersachsen.de/assets/"+details.video' controls ref="videoplayer2" autoplay></video>
                    <div class="playMe"> 
                        <div class="playVideoButton"  @click="loadVideo()">
                            <img :src="playButton" alt=""><div class="playVideoButtonText">Video abspielen</div>
                        </div>
                    </div>
                </div>
                <div class="contentDetail">
                    <div class="mainContent"> 
                        <h2>{{details.titel}}</h2>
                        <p v-html="details.paragraph"></p>
                    

                    </div>
                    <div class="personenContent">
                        <p v-html="details.personen_info" class="personenParagraph"></p>
                        <div class="personenContentImage">
                            <img :src="jahreLogo" alt="">
                        </div>
                        
                    </div>
                </div>
                <div class="closeButton" @click="closeModal()"><img :src="closeButton" alt=""></div>
            </div>
        </div>

        
    </div>
</template>

<script>
import closeButton from "@/assets/images/icons/kreuz.png"
import jahreLogo from "@/assets/images/icons/75_jahre_logo.png"
import playButton from "@/assets/images/icons/play_rot.png"
import gsap from 'gsap'
export default {
    data(){
        return {
            details:null,
            showPreview:true,
            preview:true,
            closeButton,jahreLogo,playButton,
            videoplaying:false
        }
    },
    methods:{
        closeModal(){
            this.$emit("closeDetail")
            console.log("detailView 2",this.$refs.videoplayer)
            if(this.$refs.videoplayer){
                this.$refs.videoplayer.pause()
            }
            if(this.$refs.videoplayer2){
                this.$refs.videoplayer2.pause()      
            }
        },
        showPlay(){
            if(!this.videoplaying){
                gsap.to(".playMe",
                    {
                        duration:1,
                        x:400,
                        opacity:1
                    }
                )
            }
            
        },
        hidePlay(){
            console.log("test mouseover")
            gsap.to(".playMe",
                {
                    duration:1,
                    x:-400,
                    opacity:0
                }
            )
        },
        loadVideo(){
            console.log("test")
            this.showPreview=false
            this.preview=!this.preview
            this.videoplaying=true
            this.hidePlay()
        }
    },   
    mounted(){        
        this.details=this.$store.getters.getCurrentDetail
    },

}
</script>

<style lang="scss">
    .videoBackgroundInner{
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: rgba($color: #000000, $alpha: .8);
    }
    .personenParagraph{
        padding-top: 18px;
        font-size: 14px;
    }
    .playMe{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
        opacity: 0;
        background-color: rgba($color: #2880b9, $alpha: .8);
        margin-left: -400px;

    }

   
    .personenContentImage{
        display: flex;
        justify-content: center;
    }
    .videoBoard{
        position: relative;
    }
    .previewVideo{
        &:hover{
            cursor: pointer;
        }
    }
    .playVideoButton{
        position: absolute;
        bottom: 50px;
        left: 50px;
        display: flex;
        &:hover{
            cursor: pointer;
        }
        img{
            width: 150px
        }
        .playVideoButtonText{
            color: white;
            margin-top: 70px;
            margin-left: 20px;
            font-size: 30px;
        }
    }

    .videoCard{
        position: fixed;
        width: 90%;
        max-width: 1200px;
        background: white;
        min-height: 200px;
        max-height: 90vh;
        top: 30px;
        overflow-y: auto;
        video{
            width: 99%;
            margin-top: .5%;
        }
    }
    .closeButton{
        position: absolute;
        display: block;
        right: 50px;
        top: 20px;
        font-size: 40px;
        color: white;
        width: 20px;
        height: 20px;
        z-index: 3;
        img{
            width: 50px;
            &:hover{
                cursor: pointer;
            }
        }
    }

    .contentDetail{
        display: flex;
        margin: 5%;
        h2{ font-size: 18px;}
        text-align: left;

        .mainContent{
            width: 60%;

           
        }
        .personenContent{
            width: 40%;
            text-align: left;
            padding-left: 80px;
            color: #2880b9;
            img{
                margin-top: 50px;
                width: 65%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
</style>