<template>
    <div class="introWrapper">
        <div class="backgroundTop">
             <div class="">
                <div class="top">
                    <div class="topContent">
                        <img src="@/assets/logos/logo_npa_integrationsinitiative.svg"  alt="">
                        <a href="/"><img src="@/assets/logos/logo_75_niedersachsen.svg" alt=""></a>
                    </div>
                </div>
            </div>
        </div>       
    </div>
</template>

<style scoped>
    .topContent a{
        margin-top: 35px;
    }
</style>
