<template>
  <div class="home">
    <intro></intro>
    <video-grid v-on:openDetail="showDetail()"> </video-grid>
    <middle-intro></middle-intro>

    <timeline v-on:openDetail="showDetail()"></timeline>
    <contact></contact>
    <detail-view ref="detailView" :key="viewChange" v-on:closeDetail="closeView()" class="test"></detail-view>
  </div>
</template>

<script>
// @ is an alias to /src
import MiddleIntro from '@/components/MiddleIntro.vue'
import VideoGrid from '@/components/VideoGrid.vue'
import Timeline from '@/components/Timeline.vue'
import DetailView from '@/components/DetailView.vue'
import Intro from '@/components/Intro.vue'
import Contact from '@/components/Contact.vue'

export default {
  name: 'Home',
  components:{
    VideoGrid,
    Timeline,
    DetailView,
    Intro,
    Contact,
    MiddleIntro
  },
  data () { 
    return {
      video:null,
      viewChange:false
    }
  },
  mounted(){
    this.$refs.detailView.$el.style.visibility="hidden"

  },
  methods:{
    showDetail(){
      console.log(this.$refs.detailView)
      this.viewChange = !this.viewChange
      this.$refs.detailView.$el.style.visibility="inherit"
      console.log("this.$refs.detailView.$el",this.$refs.detailView)
    },
    closeView(){
      this.$refs.detailView.$el.style.visibility="hidden"

    }
  },
  watch:{
    viewChange: function(){
 
      console.log("moving")
    }
  }

 
}
</script>
