<template>
    <div class="contactWrapper">
        <div class="backgroundContact">
            <div class="contact">
                <div class="contentContact">
                    <p style="font-weight: bold;padding-top: 20px;margin-top: 0px;">KONTAKT/IMPRESSUM</p>
                    <p>
                        Niedersächsische Staatskanzlei <br>
                
                        Geschäftsstelle "Niedersachsen packt an" <br>
                
                        Planckstraße 2 | 30169 Hannover <br>
                    
                        Telefon 0511/120-6909 <br>
                        E-Mail <a href="mailto:buendnis@niedersachsen.de" style="color: white;">buendnis@niedersachsen.de</a>  <br>
                        www.niedersachsen-packt-an.de
                    </p>
                    <div class="impressumlinks">
                        <a href="/datenschutz">DATENSCHUTZ</a> <a href="/impressum">IMPRESSUM</a>
                    </div>
                    <div class="logos">
                        <a href="https://www.youtube.com/channel/UCm4nMp1ppkGICEjXCKNlM2g"><img :src="youtube" alt=""></a>
                        <a href="https://www.facebook.com/niedersachsenpacktan"> <img :src="facebook" alt=""></a>
                        <a href="https://www.instagram.com/niedersachsenpacktan/"> <img :src="instagram" alt=""></a>
        
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import facebook from "@/assets/images/icons/facebook.svg"
import instagram from "@/assets/images/icons/instagram.svg"
import youtube from "@/assets/images/icons/youtube.svg"

export default {
    data(){
        return {
            facebook,instagram,youtube
        }
    }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'frutiger-thin';
    src: url('../assets/fonts/ftl_____0.ttf');
}
@font-face {
    font-family: 'frutiger';
    src: url('../assets/fonts/Frutiger.ttf');
}

.videoContainer{
    background-color: #3B3B3B;
    width: 100%;
    padding-bottom: 80px;
}
.videoContainer  > p{
    padding-top: 25px;
} 


.videoContainer{
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
body{
    margin: 0px;
    color: white;
    font-family: frutiger;
    font-size: 16px;
}
.whiteline{
    height: 1px;
    width: 100px;
    display: block;
    margin: 10px auto;
    background-color: white;
}

.contact{
    background-color: #585858;
    padding-bottom: 50px;
    padding-top: 40px;
}

.contact .content{
    text-align: center;
}
.contact .content p{
    line-height: 1.6;
}
.logos{
    margin:  0px auto;
    display: flex;
    justify-content: center;
}
.logos img{
    width: 20px;
    margin: 0px 5px;
}

.widthContainer{
    max-width: 1200px;
}
.contactWrapper{
    color:white;
    .backgroundContact{
        background-color: #585858;
    }
    .backgroundVideo{
        display: flex;
        justify-content: center;
        background-color: #585858;
    }
}

.impressumlinks{
    color: white;
    a{
        color:white;
        text-decoration:none;
        margin:10px
    }
    margin-bottom: 10px;
    margin-top: 20px;
}
</style>

