<template>
    <div class="gridFrame">
        <div class="gridWrapper">
                <div class="grid">
                    <div class="videoCardWrapper" v-for="videoCard,index in videoCards" :key="index" @click="openDetail(videoCard.details)" >
                        <div class="previewContainer">
                            <img :src="'http://cms.zuhause-in-niedersachsen.de/assets/'+videoCard.preview" alt="">
                        </div>
                        <div class="beschreibung">
                            <p>{{videoCard.beschreibung}}</p> 
                        </div>
                        <div class="playButton">
                            <img :src="videoplay" alt="">
                        </div>
                    
                </div>
        </div>

        </div>
        
    
        
    </div>
</template>

<script>
import videoplay from "@/assets/images/icons/play_rot.png"


export default {
    data(){ 
        return {
            videoCards:null,
            videoplay
        }
    },
    methods:{
        openDetail(card_id){
            this.$emit('openDetail')
            this.$store.dispatch("setCurrentDetail",card_id)
        },
        
    },
     async mounted(){
        await this.$store.dispatch("serverStart")
        await this.$store.dispatch("getVideos")
        await this.$store.dispatch("getDetails");

        this.videoCards=this.$store.getters.getVideos
        console.log("video cards", this.videoCards)
    }
}
</script>

<style lang="scss">
    .gridFrame{
        background-color: #3B3B3B;
        display: flex;
        justify-content: center;
        padding-bottom: 40px;
    }
    .gridWrapper{
        max-width: 1200px;
    }
    .videoCardWrapper{
        width: 32%;
        margin : .5%;
        position: relative;
        @media (max-width:425px) {
            width: 49%;
        }
       
    }
    .previewContainer{
        height: 500px;
        width: 100%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
         &:hover{
            width: calc(100% - 6px);
            height: calc(500px-6px);
            cursor: pointer;
            border: 3px solid white;
        }
    }

    .beschreibung{
        position: absolute;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: .4);
        color: white;
        padding: 0px;
        width: 100%;
    }
    .grid{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width:425px) {
            margin-left: auto;
            margin-right: auto;
        }

    }

    .playButton{
        position: absolute;
        top: 200px;
        left:40%;
        img{
            width: 70px;
        }
    }




</style>