<template>
    <div class="introWrapper">
        <top></top>
        <div class="backgroundVideo">          
            <div class="widthContainer">
                <div class="videoContainer">
                    <div class="contentVideo">
                        <h1>Zuhause in Niedersachsen</h1>
                        <div class="whiteline"></div>
                         <p>Niedersachsen ist seit seiner Gründung im Jahr 1946 ein Land der Migration. Viele Menschen unterschiedlichster Herkunft haben hier ihre Heimat gefunden.
                              Sie gestalten seither das Miteinander und die Zukunft dieses Bundeslandes in lebenswerter Weise mit. 
                            </p>
                            <p>
                                Zum 75. Landesjubiläum hat das Bündnis „Niedersachsen packt an“ zwölf Zeitzeug:innen aus allen Teilen des Landes porträtiert. 
                                Die Lebensläufe sind grundverschieden und haben doch einen gemeinsamen Kern: Sie alle sind zuhause in Niedersachsen.
                            </p>
                        <video src="@/assets/video/Zeitzeugen_Trailer_v01.mp4" poster="@/assets/images/preview_75npa.png" controls class="videoTrailer" ></video>
                       
                        
                    </div>                    
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
import Top from "@/components/Top.vue"

export default {
    components:{
        Top
    }

}
</script>

<style lang="scss" >
@font-face {
    font-family: 'frutiger-thin';
    src: url('../assets/fonts/ftl_____0.ttf');
}
@font-face {
    font-family: 'frutiger';
    src: url('../assets/fonts/Frutiger.ttf');
}
h1{
    color: white;
    font-family: frutiger-thin;
    font-size: 70px;
    font-weight: lighter;
    padding-top: 20px;
    margin-bottom: 20px;
    @media (max-width:425px) {
        font-size: 50px;
    }
}
.videoContainer{
    background-color: #3B3B3B;
    width: 100%;
    padding-bottom: 80px;
}
.videoContainer  .content > p{
    padding-top: 25px;
} 
.top{
    background-color: white;
    height: 150px;
    width: 100%;
    @media (max-width: 600px) {
        height: 230px;    
    }
   
}
.topContent{
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 12px auto;
    padding: 12px 25px;
    img{
        width:250px;
    }
    @media (max-width: 600px) {
        flex-direction: column; 
        align-content: center;
        img{
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 20px;
        }       
    }
}
.videoContainer .content{
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
body{
    margin: 0px;
    color: white;
    font-family: frutiger;
    font-size: 16px;
}
.whiteline{
    height: 1px;
    width: 100px;
    display: block;
    margin: 10px auto;
    background-color: white;
}
.videoTrailer{
    padding-top: 40px;
    width: 80%;
    height: auto;
    @media (max-width: 768px) {
        width: 100%;   
    }
}
.contact{
    background-color: #585858;
    padding-bottom: 50px;
    padding-top: 40px;
}

.contact .content{
    text-align: center;
}
.contact .content p{
    line-height: 1.6;
}
.logos{
    margin:  0px auto;
    display: flex;
    justify-content: center;
}
.logos img{
    width: 20px;
    margin: 0px 5px;
}

.widthContainer{
    max-width: 1200px;
}
.introWrapper{
    color:white;
    .backgroundTop{
        justify-content: center;
    }
    .backgroundVideo{
        display: flex;
        justify-content: center;
        background-color: #3B3B3B;
    }
}

.contentVideo{
    p{
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.7;
    }
    p:first-of-type {   
        margin-top: 40px;
    }
}
</style>