<template>
  <div class="tc-card">

    <div class="tc-top">
      <div class="horizontal-points-wrapper">
        <div class="horizontal-points">
          <img
            :src="horizontal_points"
            alt=""
            class="hor_points"
            v-for="index in 60"
            :key="index"
          />
        </div>
      </div>
      <div class="image-wrapper" >
        <img :src="assetUrl+timelineItem.icon" alt="" width="120px" class="tc-image" v-if="timelineItem.icon!=null"/>
      </div>
      <div class="vertical-points" v-if="timelineItem.preview || timelineItem.titel" >
        <img 
          :src="vertical_points"
          alt=""
          class="vert_points"
          v-for="index in 8"
          :key="index"
        />
      </div>
   

     
      
    </div>
    <div class="tc-content-down">
      <div class="tc-headline">{{timelineItem.titel}}</div>
      <div class="tc-paragraph" v-html="timelineItem.paragraph"></div>
      <div class="tc-preview" v-if="timelineItem.preview">
        <img :src="assetUrl+timelineItem.preview" alt="">
      </div>
      <div v-if="timelineItem.preview" class="videoPlay" @click="openDetail(timelineItem.details)">
        <img :src="videoplay" alt=""> <div style="font-weight:bold;" >zum Video <span v-if="timelineItem.videotitel">: {{ timelineItem.videotitel}}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import horizontal_points from "@/assets/images/zeitstrahl/zeitstrahl_punkte_horizontal.svg";
import vertical_points from "@/assets/images/zeitstrahl/zeitstrahl_punkte_vertikal.svg";
import videoplay from "@/assets/images/icons/play_schwarz.png"

export default {
  data() {
    return {
      horizontal_points,
      vertical_points,
      assetUrl: "https://cms.zuhause-in-niedersachsen.de/assets/",  
      videoplay
    };
  },
  props: {
    timelineItem: Object
  },
  methods:{
    checklength2(){
       if(this.headline=!null){
         
       }
       else{return false}
    },
    checklength(string){
      if(string=!null){
        console.log("Strings oben")
        console.log(this.headline_up)
        console.log(typeof this.headline_up)
        if(typeof  this.headline_up=='object'){
          return false
        }
        let headline=this.headline_up        
        if(typeof  this.headline_up=='string'){
          let ln=headline.length
          console.log(headline)
          console.log(ln)
          if(ln <10){return false}
        }
       
        if(string){return true}
        if(string.length<10){return false}
        else{return true}
        
        
      }else{
        return false
      }
        
    },
    openDetail(card_id){
            this.$emit('openDetail')
            this.$store.dispatch("setCurrentDetail",card_id)
    }
  }
};
</script>

<style lang="scss">

.tc-content-down {
  margin-top: 20px;
  margin-left: -5px;
  width: 60%;
  text-align: left;
}

.tc-content-top {
  min-height: 200px;
  max-height: 400px;
  margin-left: 50%;
  margin-bottom: 80px;
  position: relative;
}
.tc-headline {
  font-weight: bold;
  font-size: 15px;
}

.tc-paragraph {
  font-size: 12px;
  line-height: 1.5;
}
.tc-card {
  width: 100%;
  margin-left: 100px;
  padding-top: 30px;
}
.horizontal-points-wrapper{
  width: 100%;
  overflow: hidden;
}
.horizontal-points {
  display: flex;
  flex-direction: row;
  height: 20px;
  width: 120%;
  overflow: hidden;
}
.hor_points {
  width: 10px;
  margin-left: 1px;
}
.vert_points {
  width: 5px;
  margin-top: 1px;
}

.top-content-wrapper{
  position: absolute;
  bottom: 0;
}

.image-wrapper{
  margin-top: 80px;
}
.vertical-points {
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-top: -89px;
  margin-left: -5px;
}

.vertical-points-up div{
  display: flex;
  flex-direction: column;  
}

.vertical-points-up{
  height: 80px;
  margin-top: -180px;
  margin-left: 50%;
  overflow: hidden;
} 

.tc-image {
  position: absolute;
  margin-top: -130px;
  margin-left: -58%;
  @media(max-width: 768px) {
    margin-left: -62%;
  }
  @media (max-width:425px) {
    margin-left: -64%;
  }

}

.image-wrapper{
  img{
    width: 80px;
  }
}

.tc-preview{
  
  img{
    width: 60%;
    height: 100px;
    object-fit: 100%;
  }

}

.videoPlay{
  display: flex;
  margin-top: 10px;
  img{
    width: 20px;
    margin-right: 10px;
  }
  &:hover{
    cursor: pointer;
  }
}
</style>