<template>
    <div class="introWrapper">
       
        <div class="backgroundVideo">          
            <div class="widthContainer">
                <div class="videoContainer">
                    <div class="contentVideo">
                        <h1>75 Jahre Migration und Integration in <br> Niedersachsen</h1>
                        <div class="whiteline"></div>
                         <p>Das Land Niedersachsen wurde am 1. November 1946 gegründet. Die Bevölkerung wuchs in den 75 Jahren von 6,3 auf derzeit gut acht Millionen Menschen. 22,3 % der Bürger:innen haben eine Zuwanderungsgeschichte, sie stammen aus etwa 50 Nationen. Jede Dekade hatte geschichtliche Ereignisse, 
                             die zu Migration und Integration geführt haben und das gesellschaftliche Miteinander in Niedersachsen bis heute prägen.
                        </p>
                        <p>
                            Angefangen bei den Heimatvertriebenen nach 1945, über die frühen Gastarbeiter:innen aus Italien,
                            der Türkei und anderen Ländern in den 1950/60er Jahren und die englischen Soldaten,
                            die nach Ende der Besatzungszeit in Niedersachsen ein neues Zuhause fanden, jene Menschen, 
                            die aus Ungarn in Folge des Aufstands im Jahr 1956 flohen, die Flucht chilenischer Bürger:innen in Folge der Pinochet-Herrschaft im Jahr 1974, die Aufnahme der sogenannten „Boatpeople“ aus Vietnam in den 1970/80er Jahren, über die deutsch-russischen Spätaussiedler:innen,
                             Menschen, die in den 1990er Jahren aus dem Kosovo und Ex-Jugoslawien sowie vor dem Krieg im Irak flohen, bis hin zum arabischen Frühling und dem syrischen Bürgerkrieg der 2010er Jahre und nicht zuletzt die EU-Binnenmigrant:innen, die im Zuge der Arbeitnehmendenfreizügigkeit nach Niedersachsen kommen.
                        </p>
                       
                        
                    </div>                    
                </div>
            </div>
        </div>
       
    </div>
</template>